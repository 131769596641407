import React, { useEffect } from "react";

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import { ethers } from "ethers";

import { useRecoilState } from 'recoil';
import { Account, Balance, ChainId } from '../Store';


export function Wallet() {

  const [account, setAccount] = useRecoilState(Account);
  const [balance, setBalance] = useRecoilState(Balance);
  const [chainId, setChainId] = useRecoilState(ChainId);

  async function connectWallet() {

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();

    const _account = await signer.getAddress();
    setAccount(_account);
    const _balance = await signer.getBalance();
    setBalance(_balance);
    const _chainId = await signer.getChainId();
    setChainId(_chainId);

  }


  useEffect(() => {
    if (window.ethereum){
      window.ethereum.on('accountsChanged', function (accounts) {connectWallet()});
    }
  });


  if (!window.ethereum) {
    return (
      <Navbar>
        <Container>
          <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>No Ethereum wallet was detected. Please install{" "}
                <a href="http://metamask.io" target="_blank" rel="noreferrer">MetaMask</a>
                {" "}and connect an account to Ropsten Ethereum
              </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }

  if (chainId != "3") {
    return (
      <Navbar>
        <Container>
          <Navbar.Collapse className="justify-content-end">
            <Button onClick={connectWallet}>Connect MetaMask to Ropsten Ethereum</Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }

  if (!balance || !chainId) {
    return <Spinner animation="border" />;
  }

  
  return (
    <Navbar>
      <Container>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text className="p-4"><b>ChainId</b> : {chainId}</Navbar.Text>
          <Navbar.Text className="p-4"><b>Account</b> : {account.substr(0,5)}...{account.substr(38)}</Navbar.Text>
          <Navbar.Text className="p-4"><b>Balance</b> : {ethers.utils.formatEther(balance)}</Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );

}












