import React from "react";

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';


export function Header() {

  return (
    <Navbar>
        <Container>
        <Navbar.Brand href="#home">Decentralized applications</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>Powered by <a href="https://discord.gg/Mk4ew6ttc6" target="_blank" rel="noreferrer">Skranf</a>{" "}</Navbar.Text>
        </Navbar.Collapse>
        </Container>
    </Navbar>
  );

}












