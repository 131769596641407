import React, {useState } from "react";
import { Switch, Route } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ethers } from "ethers";

import { useRecoilState } from 'recoil';
import { Account, Balance, ChainId } from '../Store';

import { Wallet } from './Wallet';

import purchaseContract from '../contracts/Purchase.json';

const ENG = () => 
<Container>
    <h3 className="pb-4">Middleman project</h3>
    <p>Purchasing goods remotely currently requires multiple parties that need to trust each other.
    The simplest configuration involves a seller and a buyer.
    The buyer would like to receive an item from the seller and the seller would like to get money (or an equivalent) in return. </p>
    <p>In this project, both parties have to put twice the value of the item into the contract as escrow.
    As soon as this happened, the money will stay locked inside the contract until the buyer confirms that they received the item.
    After that, the buyer is returned the value (half of their deposit) and the seller gets three times the value (their deposit plus the value).
    The idea behind this is that both parties have an incentive to resolve the situation or otherwise their money is locked forever.</p>
</Container>;

const FRA = () =>
<Container>
    <h3 className="pb-4">Middleman project</h3>
    <p>L'achat de biens à distance nécessite actuellement plusieurs parties qui doivent se faire confiance.
    La configuration la plus simple implique un vendeur et un acheteur.
    L'acheteur souhaite recevoir un article du vendeur et le vendeur souhaite recevoir de l'argent (ou l'équivalent) en retour.</p>
    <p>Dans ce projet, les deux parties doivent mettre deux fois la valeur de l'article dans le contrat en tant que cautionnement. 
    Dès que cela s'est produit, l'argent restera enfermé dans le contrat jusqu'à ce que l'acheteur confirme qu'il a reçu l'article. 
    Après cela, l'acheteur reçoit la valeur (la moitié de son acompte) et le vendeur reçoit trois fois la valeur (son acompte plus la valeur). 
    L'idée derrière cela, c'est que les deux parties ont une incitation à résoudre la situation ou sinon leur argent est bloqué pour toujours.</p>
</Container>;



export function Purchase() {

    const bytecode = purchaseContract.bytecode;
    const abi = purchaseContract.abi;

    const [account, setAccount] = useRecoilState(Account);
    const [, setBalance] = useRecoilState(Balance);
    const [chainId, setChainId] = useRecoilState(ChainId);

    const [amount, setAmount] = useState("");

    const [addressContract, setAddressContract] = useState("");
    const [balanceContract, setBalanceContract] = useState("");
    const [addressSeller, setAddressSeller] = useState("");
    const [value, setValue] = useState("");

    const [addressContractAccept, setAddressContractAccept] = useState("");
    const [addressSellerAccept, setAddressSellerAccept] = useState("");
    const [valueAccept, setValueAccept] = useState("");
    const [balanceContractAccept, setBalanceContractAccept] = useState("");
    const [addressBuyer, setAddressBuyer] = useState("");

    const [addressContractControl, setAddressContractControl] = useState("");
    const [addressSellerControl, setAddressSellerControl] = useState("");
    const [valueControl, setValueControl] = useState("");
    const [balanceContractControl, setBalanceContractControl] = useState("");
    const [addressBuyerControl, setAddressBuyerControl] = useState("");

    const [addressContractReceived, setAddressContractReceived] = useState("");
    const [balanceContractReceived, setBalanceContractReceived] = useState("");
    const [valueReceived, setValueReceived] = useState("");
    const [addressSellerReceived, setAddressSellerReceived] = useState("");
    const [balanceSellerReceived, setBalanceSellerReceived] = useState("");
    const [addressBuyerReceived, setAddressBuyerReceived] = useState("");
    const [balanceBuyerReceived, setBalanceBuyerReceived] = useState("");


    async function updateWallet(){

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const _account = await signer.getAddress();
        setAccount(_account);
        const _balance = await signer.getBalance();
        setBalance(_balance);
        const _chainId = await signer.getChainId();
        setChainId(_chainId);

    }

    async function deployContract(){

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();

        const factory = new ethers.ContractFactory(abi, bytecode, signer);
        const options = {value: ethers.utils.parseEther(amount.toString())}
        const contract = await factory.deploy(options);
        await contract.deployTransaction.wait();

        setAddressContract(contract.address);

        const _seller = await contract.seller();
        setAddressSeller(_seller);

        const _value = await contract.value();
        setValue(ethers.utils.formatEther(_value));

        const _balance = await provider.getBalance(contract.address);
        setBalanceContract(ethers.utils.formatEther(_balance));

        updateWallet();

    }

    async function readContract(){

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(addressContractAccept, abi, signer);

        const _seller = await contract.seller();
        setAddressSellerAccept(_seller);

        const _value = await contract.value();
        setValueAccept(ethers.utils.formatEther(_value));

        const _balance = await provider.getBalance(addressContractAccept);
        setBalanceContractAccept(ethers.utils.formatEther(_balance));

    }

    async function acceptContract(){

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(addressContractAccept, abi, signer);
        const options = {value: ethers.utils.parseEther(balanceContractAccept.toString())};
        const tx = await contract.confirmPurchase(options);
        await tx.wait();

        const _buyer = await contract.buyer();
        setAddressBuyer(_buyer);

        updateWallet();

    }

    async function controlContract(){

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(addressContractControl, abi, signer);

        const _seller = await contract.seller();
        setAddressSellerControl(_seller);

        const _buyer = await contract.buyer();
        setAddressBuyerControl(_buyer);

        const _value = await contract.value();
        setValueControl(ethers.utils.formatEther(_value));

        const _balance = await provider.getBalance(addressContractControl);
        setBalanceContractControl(ethers.utils.formatEther(_balance));

    }

    async function readContractReceived(){

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(addressContractReceived, abi, signer);

        const _seller = await contract.seller();
        setAddressSellerReceived(_seller);
        const _balanceSeller = await provider.getBalance(_seller);
        setBalanceSellerReceived(ethers.utils.formatEther(_balanceSeller));

        const _buyer = await contract.buyer();
        setAddressBuyerReceived(_buyer);
        const _balanceBuyer = await provider.getBalance(_buyer);
        setBalanceBuyerReceived(ethers.utils.formatEther(_balanceBuyer));

        const _value = await contract.value();
        setValueReceived(ethers.utils.formatEther(_value));

        const _balance = await provider.getBalance(addressContractReceived);
        setBalanceContractReceived(ethers.utils.formatEther(_balance));

    }

    async function receivedContract(){

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(addressContractReceived, abi, signer);
        const tx = await contract.confirmReceived();
        await tx.wait();

        readContractReceived();
        updateWallet();

    }

    return (
        <Container className="p-5 mb-4 bg-light">
            
            <Switch>
                <Route path="/ENG"><ENG/></Route>
                <Route path="/FRA"><FRA/></Route>
                <Route path="/"><ENG/></Route>
            </Switch>

            {(chainId == "3") ? (
                <Container>

                    <Container className="p-3 mb-4 bg-white">
                        <h5>Step 1: the seller deploys the smart contract on the blockchain.</h5>
                        <p>The seller puts twice the value of the item and gets the smart contract address.
                        The seller sends this address to the buyer.</p>
                        <Form className="pb-3">
                            <Row>
                                <Col>
                                    <Form.Control placeholder="Value of the item to sell (ETH)" onChange={e => setAmount((e.target.value)*2)}/>
                                </Col>
                                <Col>
                                    <Button onClick={deployContract}>Deploy the smart contract</Button>
                                </Col>
                            </Row>
                        </Form>
                        <p><b>Contract Address : </b>{addressContract}</p>
                        <p><b>Value of the item : </b>{value}</p>
                        <p><b>Money locked : </b>{balanceContract}</p>
                        <p><b>Seller Address : </b>{addressSeller}</p>
                    </Container>

                    <Container className="p-3 mb-4 bg-white">
                        <h5>Step 2: the buyer reads and accepts the smart contract on the blockchain.</h5>
                        <p>The buyer puts twice the value of the item to the smart contract.</p>
                        <Form className="pb-3">
                            <Row>
                                <Col>
                                    <Form.Control placeholder="Contract address to accept" onChange={e => setAddressContractAccept(e.target.value)}/>
                                </Col>
                                <Col>
                                    <Button onClick={readContract}>Read the smart contract</Button>
                                </Col>
                            </Row>
                        </Form>
                        <p><b>Contract Address : </b>{addressContractAccept.toString()}</p>
                        <p><b>Value of the item : </b>{valueAccept}</p>
                        <p><b>Money locked : </b>{balanceContractAccept}</p>
                        <p><b>Seller Address : </b>{addressSellerAccept}</p>
                        <Button onClick={acceptContract}>Accept the smart contract</Button>
                        <p className="pt-3" ><b>Buyer Address : </b>{addressBuyer}</p>
                    </Container>

                    <Container className="p-3 mb-4 bg-white">
                        <h5>Step 3: the seller checks buyer acceptation and sends the item.</h5>
                        <p>The money will stay locked inside the contract until the buyer confirms that they received the item.</p>
                        <Form className="pb-3">
                            <Row>
                                <Col>
                                    <Form.Control placeholder="Contract address to check" onChange={e => setAddressContractControl(e.target.value)}/>
                                </Col>
                                <Col>
                                    <Button onClick={controlContract}>Check the smart contract</Button>
                                </Col>
                            </Row>
                        </Form>
                        <p><b>Contract Address : </b>{addressContractControl.toString()}</p>
                        <p><b>Value of the item : </b>{valueControl}</p>
                        <p><b>Money locked : </b>{balanceContractControl}</p>
                        <p><b>Seller Address : </b>{addressSellerControl}</p>
                        <p><b>Buyer Address : </b>{addressBuyerControl}</p>
                    </Container>

                    <Container className="p-3 mb-4 bg-white">
                        <h5>Step 4: the buyer confirms that they received the item.</h5>
                        <p>After that, the buyer is returned the value (half of their deposit) and the seller gets three times the value (their deposit plus the value).</p>
                        <Form className="pb-3">
                            <Row>
                                <Col>
                                    <Form.Control placeholder="Contract address to confirm received" onChange={e => setAddressContractReceived(e.target.value)}/>
                                </Col>
                                <Col>
                                    <Button onClick={readContractReceived}>Read the smart contract</Button>
                                </Col>
                            </Row>
                        </Form>
                        <p><b>Contract Address : </b>{addressContractReceived.toString()}</p>
                        <p><b>Value of the item : </b>{valueReceived}</p>
                        <p><b>Money locked : </b>{balanceContractReceived}</p>
                        <p><b>Seller Address : </b>{addressSellerReceived}</p>
                        <p><b>Seller balance : </b>{balanceSellerReceived}</p>
                        <p><b>Buyer Address : </b>{addressBuyerReceived}</p>
                        <p><b>Buyer Balance : </b>{balanceBuyerReceived}</p>
                        <Button onClick={receivedContract}>Confirm received item</Button>
                    </Container>
                </Container>
            ):(
                <Container><Wallet /></Container>
            )}



        </Container>
    );

}












