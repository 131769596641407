import React from 'react';

import Container from 'react-bootstrap/Container';

import { Home } from './Home';
import { Purchase } from './Purchase';
import { Nft } from './Nft';


export function Main(){

  return (

    <Container className="mt-4">

        <Home id="home"/>
        <Purchase id="middleman"/>
        <div id="nft"><Nft /></div>

    </Container>

  );

}
