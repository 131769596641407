import React from "react";
import { Switch, Route } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

const ENG = () =>
<Container>
    <h3 className="pb-4">Welcome</h3>
    <p>A decentralized application (dapp) is an application built on a decentralized network that combines a smart contract and a frontend user interface. 
    Here, you will find sample dapps built on Ropsten Ethereum, 
    an Ethereum test network that allows for blockchain development testing before deployment on Ethereum network.</p>
</Container>;

const FRA = () =>
<Container>
    <h3 className="pb-4">Bienvenue</h3>
    <p>Une application décentralisée (dapp) est une application construite sur un réseau décentralisé qui combine un contrat intelligent et une interface utilisateur frontale. 
    Ici, vous trouverez des exemples de dapps construits sur Ropsten Ethereum, 
    un réseau de test Ethereum qui permet de tester le développement de la blockchain avant le déploiement sur le réseau Ethereum.</p>
</Container>;

export function Home() {

  return (
    <Container className="p-5 mb-4 bg-light">
        <Switch>
            <Route path="/ENG"><ENG/></Route>
            <Route path="/FRA"><FRA/></Route>
            <Route path="/"><ENG/></Route>
        </Switch>
    </Container>
  );

}












