import React from 'react';
import { MemoryRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import Container from 'react-bootstrap/Container';

import { Wallet } from './components/Wallet';
import { Header } from './components/Header';
import { Menu } from './components/Menu';
import { Main } from './components/Main';

import './App.css';


function App(){

  return (

    <RecoilRoot>
    <MemoryRouter>

      <Container fluid>

        <Header/>
        <Wallet/>
        <Menu/>
        <Main/>

      </Container>

    </MemoryRouter>
    </RecoilRoot>

  );

}

export default App;
