import React from "react";

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { LinkContainer } from 'react-router-bootstrap';


export function Menu() {

  return (
    <Navbar expand="lg" sticky="top" className="bg-white">
        <Container >
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
            <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
            >
                <NavDropdown title="Dapps list ">
                    <NavDropdown.Item href="#middleman">Middleman project</NavDropdown.Item>
                    <NavDropdown.Item href="#nft">NFT project</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Tutorial ">
                    <NavDropdown.Item href="https://medium.com/@stadja/how-to-install-metamask-plugin-and-get-a-ropsten-test-ethereum-51e761c9f975" target="_blank" rel="noreferrer">How to get Ropsten test Ethereum with MetaMask</NavDropdown.Item>
                    <NavDropdown.Item href="https://faucet.egorfine.com/" target="_blank" rel="noreferrer">How to receive Ropsten ETH</NavDropdown.Item>
                </NavDropdown>
            </Nav>
            <ButtonToolbar className="custom-btn-toolbar">
                <LinkContainer to="/ENG">
                    <Button className="bg-white" variant="light">🇬🇧</Button>
                </LinkContainer>
                <LinkContainer to="/FRA">
                    <Button className="bg-white" variant="light">🇫🇷</Button>
                </LinkContainer>
            </ButtonToolbar>
        </Navbar.Collapse>
        </Container>
    </Navbar>
  );

}












