import React, { useState, useEffect } from "react";
import { Switch, Route } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ethers } from "ethers";

import { useRecoilState } from 'recoil';
import { Account, ChainId } from '../Store';

import { Wallet } from './Wallet';

import FiredGuys from '../contracts/FiredGuys.json';

const contractAddress = '0x5FAEaE2098468EBa3C9dCa2F98DABBFfB54AC80D';

const ENG = () => 
<Container>
    <h3 className="pb-4">NFT project</h3>
    <p>The ultimate impact of NFTs on business and culture remains to be seen.
    Today it is tempting to label NFTs as purely artwork, their usefulness goes far beyond digital collectibles and profile pictures used for social media influence :
    gaming, metaverse, domain names, music, events, identify, memes ...</p>
    <p>This project creates and deploys an ERC-721 smart contract on the Ropsten test network.
    You can use this smart contract to mint NFT against 0.05 rETH.</p>
</Container>;

const FRA = () =>
<Container>
    <h3 className="pb-4">NFT project</h3>
    <p>L'impact ultime des NFT sur les entreprises et la culture reste à voir.
    Aujourd'hui, il est tentant de qualifier les NFT de purement artistiques, leur utilité va bien au-delà des objets de collection numériques et des images de profil utilisées pour l'influence des médias sociaux :
    gaming, métaverse, noms de domaine, musique, événements, identifier, mèmes...</p>
    <p>Ce projet crée et déploie un contrat intelligent ERC-721 sur le réseau de test de Ropsten.
    Vous pouvez tester ce contrat intelligent pour mint NFT contre 0.05 rETH.</p>
</Container>;



export function Nft() {

    const [account] = useRecoilState(Account);
    const [chainId] = useRecoilState(ChainId);

    

    function NftsViews(){

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, FiredGuys.abi, signer);

      function NFTImage({ tokenId }) {

            const metadataURI = `Qmdqf547Pk4zsQjtEHQwr7hszYn4NhSaiZrNcM3JRob64W/${tokenId}.json`;
            const imageURI = `https://gateway.pinata.cloud/ipfs/Qma9dDqojV7yQcEaQESKsVS35XLGAhVbT9ue5iDuRjUEeK/${tokenId}.png`;
          
            const [isMinted, setIsMinted] = useState(false);

            async function getMintedStatus() {
              const result = await contract.isContentOwned(tokenId);
              setIsMinted(result);
            };

            useEffect(() => { getMintedStatus() }, [isMinted]);
          
            const mintToken = async () => {
              const result = await contract.payToMint(account, metadataURI, tokenId, { value: ethers.utils.parseEther('0.05') });
              await result.wait();
              getMintedStatus();
            };
          
            async function getURI() {
              const owner = await contract.ownerOf(tokenId);
              alert("Owner : " + owner);
            }

            return (

              <Container className="d-grid p-3 mb-4 bg-light justify-content-md-center">
                <Image className="p-3" rounded src={isMinted ? imageURI : 'http://skranf.me/placeholder.png'}></Image>
                {!isMinted ? (<Button onClick={mintToken}>Mint</Button>) : (<Button onClick={getURI}>Taken! Show owner</Button>)}
              </Container>

            );
      }


      return (

        <Container className="p-3 mb-4 bg-white">

          <Row className="justify-content-md-center">
              <Col><NFTImage tokenId={0}/></Col>
              <Col><NFTImage tokenId={1}/></Col>
              <Col><NFTImage tokenId={2}/></Col>
          </Row>
          <Row className="justify-content-md-center">
              <Col><NFTImage tokenId={3}/></Col>
              <Col><NFTImage tokenId={4}/></Col>
              <Col><NFTImage tokenId={5}/></Col>
          </Row>
          <Row className="justify-content-md-center">
              <Col><NFTImage tokenId={6}/></Col>
              <Col><NFTImage tokenId={7}/></Col>
              <Col><NFTImage tokenId={8}/></Col>
          </Row>

      </Container>

      );
      
    }


    return (
        <Container className="p-5 mb-4 bg-light">
            
            <Switch>
                <Route path="/ENG"><ENG/></Route>
                <Route path="/FRA"><FRA/></Route>
                <Route path="/"><ENG/></Route>
            </Switch>
            {(chainId == "3") ? ( <NftsViews/>) : (<Container><Wallet/></Container>)}

        </Container>
    );

}












